import React from 'react'
import '../styles/Footer.css'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'

function Footer() {
	function twitterClick() {
		window.open('https://twitter.com/sociometrik_org')
	}

	function linkedinClick() {
		window.open('https://www.linkedin.com/company/sociometrik/')
	}

	return (
		<footer>
			<div className='footer-section-mobile'>
				<img src={require('../images/logo.webp')} alt='logo' />
				<div className='social-mobile'>
					<h4>Reach out:</h4>
					<div className='social-links'>
						<button onClick={twitterClick} id='twitter'></button>
						<button onClick={linkedinClick} id='linkedin'></button>
					</div>
					<p>contact@sociometrik.com</p>
					<p>+919821590270</p>
				</div>
			</div>
			<div className='footer-links-container'>
				<div className='footer-links'>
					<h4>Features</h4>
					<p>Site Selection</p>
					<p>Site Recommendation</p>
					<p>Market Expansion</p>
					<p>Site Monitoring</p>
				</div>
				{/* <div className="footer-links">
          <h4>Solutions</h4>
          <p>Identify Growth Markets</p>
          <p>Identify Target Audience</p>
          <p>Optimize Existing Retail Portfolio</p>
          <p>Track & Monitor Footfall</p>
          <p>Optimize Distribution Network</p>
          <p>Identify Best Location For OOH Advertising</p>
        </div> */}
				<div className='footer-links'>
					<h4>Industries</h4>
					<NavLink to={'/malls'}>Malls </NavLink>
					<Link to={'/fashion-apparel'}>Fashion Apparel</Link>
					<Link to={'/beauty-and-skincare'}>Beauty & skincare </Link>
					<Link to={'/qsr'}>QSR</Link>
					<Link to={'/jewellery-and-wedding-wear'}>
						Jewellery & Weddingwear
					</Link>
					<Link to={'/sports-and-athleisure'}>Sports & Athleisure </Link>
					<Link to={'/luxury-goods'}>Bridge to Luxury Brands </Link>
				</div>
				<div className='footer-links social-pc'>
					<h4>Reach out:</h4>
					<div className='social-links'>
						<button onClick={twitterClick} id='twitter'></button>
						<button onClick={linkedinClick} id='linkedin'></button>
					</div>
					<p>contact@sociometrik.com</p>
					<p>+919821590270</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer
