import React, { useEffect, useState } from 'react'
import DemoForm from './DemoForm'
import { HashLink } from 'react-router-hash-link'
import '../styles/Header.css'
import dd from '../images/form-dropdown.png'
import menu from '../images/menu.svg'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

function Header() {
	const [demo, setDemo] = useState(false)
	const [showDD, setShowDD] = useState(false)
	const [isCollapsed, setIsCollapsed] = useState(true)

	function handleClick() {
		setDemo(true)
	}

	return (
		<>
			<header>
				<Link to={'/'}>
					<img
						src={require('../images/logo.webp')}
						alt='logo'
						className='logo'
					/>
				</Link>
				<div className='navbar'>
					<ul className={`header-links ${!isCollapsed ? 'showNav' : ''}`}>
						<li>
							<Link to='/' smooth>
								Home
							</Link>
						</li>
						<li className='dropdownLink'>
							<button onClick={() => setShowDD(!showDD)}>
								Industry{' '}
								<span style={{ width: '13px', height: '7px' }}>
									<img
										src={dd}
										alt=''
										width={13}
										height={7}
										className={` ${showDD ? 'rotate' : ''}`}
									/>
								</span>
							</button>
							<div className={`ddList ${showDD ? 'show' : ''}`}>
								<NavLink to={'/malls'}>Malls </NavLink>
								<Link to={'/fashion-apparel'}>Fashion Apparel</Link>
								<Link to={'/beauty-and-skincare'}>Beauty & skincare </Link>
								<Link to={'/qsr'}>QSR</Link>
								<Link to={'/jewellery-and-wedding-wear'}>
									Jewellery & Weddingwear
								</Link>
								<Link to={'/sports-and-athleisure'}>Sports & Athleisure </Link>
								<Link to={'/luxury-goods'}>Bridge to Luxury Brands </Link>
							</div>
						</li>
						<li>
							<HashLink to='#solutions' smooth>
								Solutions
							</HashLink>
						</li>
						<li>
							<HashLink to='#data' smooth>
								Data
							</HashLink>
						</li>
					</ul>
					<button onClick={handleClick}>Request Demo</button>
					<button
						className='navBtn'
						onClick={() => setIsCollapsed(!isCollapsed)}>
						<img src={menu} alt='' width={20} height={12} />
					</button>
				</div>
			</header>
			{demo && <DemoForm setDemo={setDemo} />}
		</>
	)
}

export default Header
