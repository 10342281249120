import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/innerPage.css';
import audience from '../images/beauty/1.png';
import store from '../images/beauty/2.png';
import benchmarking from '../images/beauty/3.png';
import promotion from '../images/beauty/4.png';

function BeautySkincare() {
  return (
    <>
      <Header />
      <div className="header">
        <h1>
          <span className="higlightText">Beauty & Skincare </span> use case
        </h1>
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p> */}
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            <span className="higlightText">Localized Product </span> Launches
          </h2>
          <p className="content">
            Launch new beauty and skincare products in areas with the highest
            demand. Use location-based data to understand regional preferences
            and ensure successful product introductions.
          </p>
        </div>
        <div>
          <img src={audience} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            Customer <span className="higlightText"> Profile Segmentation</span>
          </h2>
          <p className="content">
            Segment customers based on detailed demographic and behavioral data.
            Create personalized beauty and skincare experiences tailored to the
            unique needs of different customer groups.
          </p>
        </div>
        <div>
          <img src={store} alt="" />
        </div>
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            Optimized <span className="higlightText"> Shelf Space</span>
          </h2>
          <p className="content">
            Allocate shelf space based on real-time sales data and customer
            preferences. Maximize product visibility and boost sales by stocking
            the most in-demand items.
          </p>
        </div>
        <div>
          <img src={benchmarking} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            Targeted <span className="higlightText"> Promotions </span>
          </h2>
          <p className="content">
            Implement data-driven marketing campaigns that resonate with your
            audience. Use insights to deliver the right promotions at the right
            time, increasing customer engagement and sales.
          </p>
        </div>
        <div>
          <img src={promotion} alt="" />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BeautySkincare;
