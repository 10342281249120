import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/innerPage.css';
import audience from '../images/malls/1.png';
import store from '../images/malls/2.png';
import benchmarking from '../images/malls/3.png';
import promotion from '../images/malls/4.png';

function Malls() {
  return (
    <>
      <Header />
      <div className="header">
        <h1>
          <span className="higlightText">Malls</span> use case
        </h1>
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p> */}
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            Enhanced <span className="higlightText">Audience Profiling</span>
          </h2>
          <p className="content">
            Leverage our data intelligence to gain deep insights into your mall
            visitors demographics, behaviours, and preferences. Tailor marketing
            strategies and brand offerings to match their needs, driving
            engagement and sales.
          </p>
        </div>
        <div>
          <img src={audience} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            <span className="higlightText">Optimised Store</span> Mix
          </h2>
          <p className="content">
            Use our dynamic data on consumer trends and local demand to optimize
            your mall's retail mix. Ensure the right brands and categories are
            represented, enhancing customer satisfaction and increasing
            footfall.
          </p>
        </div>
        <div>
          <img src={store} alt="" />
        </div>
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            Competitive <span className="higlightText">Benchmarking</span>
          </h2>
          <p className="content">
            Benchmark your mall's performance against competitors using
            real-time data. Understand market positioning, identify gaps, and
            make informed decisions to stay ahead in the retail landscape.
          </p>
        </div>
        <div>
          <img src={benchmarking} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            <span className="higlightText">Event and Promotion </span>{' '}
            Effectiveness
          </h2>
          <p className="content">
            Measure the impact of events and promotions on foot traffic and
            sales. Use actionable insights to plan future activities that
            resonate with your target audience, ensuring maximum ROI for
            marketing spends.
          </p>
        </div>
        <div>
          <img src={promotion} alt="" />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Malls;
