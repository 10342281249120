import React from 'react'
import '../styles/UseCases.css'
import { NavLink } from 'react-router-dom'
import malls from '../images/industry/bi_buildings.png'
import qsr from '../images/industry/teenyicons_shop-outline.png'
import bs from '../images/industry/icon-park-outline_beauty.png'
import fashion from '../images/industry/icon-park_clothes-suit.png'
import jewellery from '../images/industry/file-icons_ring.png'
import sports from '../images/industry/fluent-mdl2_more-sports.png'
import brands from '../images/industry/Vector.png'
import ethnic from '../images/industry/ethnicwear.png'

function UseCases() {
	return (
		<div className='useCaseSection'>
			<div className='heading-container'>
				<h1 className='headingUseCase'>Industry specific use cases</h1>
				<p style={{ textAlign: 'center' }} className='subtext'>
					Find out how we can help your sector
				</p>
			</div>
			<div className='industries'>
				<NavLink to={'/malls'} className='industryCard'>
					<div className='indIcon'>
						<img src={malls} alt='' />
					</div>
					<div>
						<h3>Malls</h3>
						<p className='ind-para'>Optimize tenant mix across key regions.</p>
						<span>See More &gt;</span>
					</div>
				</NavLink>
				<NavLink to={'/qsr'} className='industryCard'>
					<div className='indIcon'>
						<img src={qsr} alt='' />
					</div>
					<div>
						<h3>Restaurant</h3>
						<p className='ind-para'>
							Boost footfall through targeted store growth.
						</p>
						<span>See More &gt;</span>
					</div>
				</NavLink>
				<NavLink to={'/beauty-and-skincare'} className='industryCard'>
					<div className='indIcon'>
						<img src={bs} alt='' />
					</div>
					<div>
						<h3>Beauty & Skincare</h3>
						<p className='ind-para'>
							Expand in high-potential catchment areas.
						</p>
						<span>See More &gt;</span>
					</div>
				</NavLink>
				<NavLink to={'/fashion-apparel'} className='industryCard'>
					<div className='indIcon'>
						<img src={fashion} alt='' />
					</div>
					<div>
						<h3>Fashion Apparel</h3>
						<p className='ind-para'>Align stores with regional demand.</p>
						<span>See More &gt;</span>
					</div>
				</NavLink>
				<NavLink to={'/jewellery-and-wedding-wear'} className='industryCard'>
					<div className='indIcon'>
						<img src={jewellery} alt='' />
					</div>
					<div>
						<h3>Jewellery & Wedding wear</h3>
						<p className='ind-para'>Expand into luxury-focused cities.</p>
						<span>See More &gt;</span>
					</div>
				</NavLink>
				<NavLink to={'/sports-and-athleisure'} className='industryCard'>
					<div className='indIcon'>
						<img src={sports} alt='' />
					</div>
					<div>
						<h3>Sports & Athleisure</h3>
						<p className='ind-para'>
							Identify growth regions and optimize stores.
						</p>
						<span>See More &gt;</span>
					</div>
				</NavLink>
				<NavLink to={'/homelifestyle'} className='industryCard'>
					<div className='indIcon'>
						<img src={brands} alt='' />
					</div>
					<div>
						<h3>Home & Lifestyle</h3>
						<p className='ind-para'>
							Refine strategy for tiered city expansions.
						</p>
						<span>See More &gt;</span>
					</div>
				</NavLink>
				<NavLink to={'/ethnicwear'} className='industryCard'>
					<div className='indIcon'>
						<img src={ethnic} alt='' />
					</div>
					<div>
						<h3>Ethnic Wear</h3>
						<p className='ind-para'>
							Connect with the right audience for maximum impact
						</p>
						<span>See More &gt;</span>
					</div>
				</NavLink>
			</div>
		</div>
	)
}

export default UseCases
