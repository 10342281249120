import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/innerPage.css';
import audience from '../images/luxury/1.png';
import store from '../images/luxury/2.png';
import benchmarking from '../images/luxury/3.png';
import promotion from '../images/luxury/4.png';

function LuxuryGoods() {
  return (
    <>
      <Header />
      <div className="header">
        <h1>
          <span className="higlightText">Luxury Goods </span> use case
        </h1>
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p> */}
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            <span className="higlightText"> High-Value Customer </span>{' '}
            Targeting
          </h2>
          <p className="content">
            Identify and target high-value customers for luxury goods using
            detailed demographic and behavioral data. Focus your efforts on
            those most likely to make significant purchases.
          </p>
        </div>
        <div>
          <img src={audience} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            Exclusive{' '}
            <span className="higlightText"> Shopping Experiences</span>
          </h2>
          <p className="content">
            Offer exclusive, personalized shopping experiences for luxury
            customers. Enhance customer satisfaction and loyalty by catering to
            their unique preferences and expectations.
          </p>
        </div>
        <div>
          <img src={store} alt="" />
        </div>
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            Market <span className="higlightText"> Trend Analysis</span>
          </h2>
          <p className="content">
            Stay ahead of luxury market trends by analyzing customer preferences
            and buying behavior. Stock the most sought-after items to attract
            affluent customers.
          </p>
        </div>
        <div>
          <img src={benchmarking} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            Tailored{' '}
            <span className="higlightText"> Marketing Strategies </span>{' '}
          </h2>
          <p className="content">
            Design and implement marketing campaigns that resonate with luxury
            buyers. Use data-driven insights to create targeted promotions that
            drive engagement and sales.
          </p>
        </div>
        <div>
          <img src={promotion} alt="" />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default LuxuryGoods;
