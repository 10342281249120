import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/innerPage.css';
import audience from '../images/jewellery/1.png';
import store from '../images/jewellery/2.png';
import benchmarking from '../images/jewellery/3.png';
import promotion from '../images/jewellery/4.png';

function JewelleryWeddingWear() {
  return (
    <>
      <Header />
      <div className="header">
        <h1>
          <span className="higlightText">Jewellery & Wedding Wear </span> use
          case
        </h1>
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p> */}
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            Market <span className="higlightText"> Demand Analysis </span>
          </h2>
          <p className="content">
            Analyze market data to identify regions with high demand for jewelry
            and wedding wear. Focus your efforts on areas with the greatest
            sales potential.
          </p>
        </div>
        <div>
          <img src={audience} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            Customer{' '}
            <span className="higlightText"> Experience Enhancement</span>
          </h2>
          <p className="content">
            Offer personalized shopping experiences based on customer
            preferences and buying behavior. Create memorable moments that drive
            repeat business and loyalty.
          </p>
        </div>
        <div>
          <img src={store} alt="" />
        </div>
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            Seasonal <span className="higlightText"> Trend Insights</span>
          </h2>
          <p className="content">
            Stay ahead of seasonal trends by monitoring customer preferences and
            purchasing patterns. Stock the right products to meet demand during
            peak wedding seasons.
          </p>
        </div>
        <div>
          <img src={benchmarking} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            Strategic <span className="higlightText"> Promotions </span>
          </h2>
          <p className="content">
            Use data-driven insights to design targeted promotional campaigns.
            Attract brides and grooms-to-be with offers that align with their
            tastes and wedding plans.
          </p>
        </div>
        <div>
          <img src={promotion} alt="" />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default JewelleryWeddingWear;
