import React, { useEffect, useState } from 'react'
import './styles/App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { getSeo } from './utils/directus'
import Home from './pages/Home'
import Malls from './pages/Malls'
import FashionApparel from './pages/FashionApparel'
import BeautySkincare from './pages/BeautySkincare'
import QSR from './pages/QSR'
import JewelleryWeddingWear from './pages/JewelleryWeddingWear'
import SportsAthleisure from './pages/SportsAthleisure'
import LuxuryGoods from './pages/LuxuryGoods'
import ScrollToTop from './components/ScrollToTop'

const GTM_ID = 'G-BE2MF0JQXF' // OUR_TRACKING_ID
const tagManagerArgs = {
	gtmId: GTM_ID,
}

TagManager.initialize(tagManagerArgs)

function App() {
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')

	useEffect(() => {
		const fetchSeo = async () => {
			try {
				const seo = await getSeo()

				Array.isArray(seo) && setTitle(seo[0]?.Title)
				Array.isArray(seo) && setDescription(seo[0].Description)
			} catch (err) {
				console.log(err)
			}
		}

		fetchSeo()
	}, [])

	return (
		<HelmetProvider>
			<Helmet>
				<meta charSet='utf-8' />
				<link rel='icon' type='image/x-icon' href='/favicon.ico' />
				<title>Sociometrik - AI-powered Retail Intelligence</title>
				<link rel='canonical' href='https://sociometrik.com/' />
				<meta
					name='description'
					content={
						'Access quick & ready data insights on location profiles, consumer behavior, and retail landscape to build a data-driven retail strategy for your business.'
					}
				/>
			</Helmet>
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/malls' element={<Malls />} />
					<Route path='/fashion-apparel' element={<FashionApparel />} />
					<Route path='/beauty-and-skincare' element={<BeautySkincare />} />
					<Route path='/qsr' element={<QSR />} />
					<Route
						path='/jewellery-and-wedding-wear'
						element={<JewelleryWeddingWear />}
					/>
					<Route path='/sports-and-athleisure' element={<SportsAthleisure />} />
					<Route path='/luxury-goods' element={<LuxuryGoods />} />
				</Routes>
			</BrowserRouter>
		</HelmetProvider>
	)
}

export default App
