import React from 'react'
import '../styles/ClientsSay.css'
import { SwiperSlide, Swiper } from 'swiper/react'
// import { Pagination } from 'swiper/modules';
import quote from '../images/quote-left.svg'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

function ClientsSay() {
	return (
		<div className='testimonial-section'>
			<h2 className='heading'>What our clients say</h2>

			<Swiper
				slidesPerView={1.2}
				spaceBetween={30}
				centeredSlides={true}
				autoplay={true}
				loop={true}
				breakpoints={{
					768: {
						slidesPerView: 2.5,
						spaceBetween: 30,
					},
					1200: {
						slidesPerView: 3.5,
						spaceBetween: 50,
					},
				}}
				// pagination={{
				//   clickable: true,
				// }}
				// modules={[Pagination]}
				className='clientSwiper'>
				<SwiperSlide>
					<div>
						<div className='profileImg'>
							<img src={quote} alt='' />
						</div>
					</div>
					<div>
						<h3>Rehan Huck</h3>
						<h5>Vice President, DLF Malls</h5>
						<p>
							Sociometrik's dynamic catchment insights have been a game-changer
							for us to deeply understand our catchment audiences and build our
							brand mix in a data-driven manner. Their insights on consumer
							behaviour and retail category preferences have been really useful
							in creating a retail product that truly caters to the needs and
							preferences of our catchment residents.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<div className='profileImg'>
							<img src={quote} alt='' />
						</div>
					</div>
					<div>
						<h3>Ravinder Choudhary</h3>
						<h5>Vice President, Unity Group (VEGAS Malls)</h5>
						<p>
							Sociometrik's insights on visitor profiles coming to our malls
							have given us some sort of a superpower to deeply understand our
							visitors better than ever. We can now easily understand where our
							visitors are coming from, what consumer profiles are visiting us
							and a lot more. This has made our marketing, event planning &
							overall operations a lot more targeted and sharper.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<div className='profileImg'>
							<img src={quote} alt='' />
						</div>
					</div>
					<div>
						<h3>Aparajita Rajpal</h3>
						<h5>AVP - Marketing & Leasing - Brahma Group</h5>
						<p>
							Sociometrik’s comprehensive data reports were pivotal in enhancing
							our understanding of the local market as we prepare to launch our
							new project in Gurugram. The insights provided a deep dive into
							consumer behavior, preferences, competitive landscape, and
							demographic trends within our primary catchment area,
							significantly informing our strategy for the upcoming project.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<div className='profileImg'>
							<img src={quote} alt='' />
						</div>
					</div>
					<div>
						<h3>Pranav Bajaj</h3>
						<h5>Co-Founder, Medulance</h5>
						<p>
							Sociometrik is helping us on our journey to revolutionize
							emergency response services. Their location intelligence offer
							crucial insights into high-demand areas, optimizing our resource
							allocation.
						</p>
						<p>
							By analyzing demographic and mobility data, we identified regions
							and places requiring enhanced coverage.{' '}
						</p>
						<p>
							We are excited to continue exploring their capabilities, allowing
							us to serve communities better.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div>
						<div className='profileImg'>
							<img src={quote} alt='' />
						</div>
					</div>
					<div>
						<h3>Sheena Khurana</h3>
						<h5>Co-Founder, Zenma</h5>
						<p>
							Partnering with Sociometrik has transformed Zenma Coffee's market
							strategy. By analyzing foot traffic to current brands and
							information on consumer profiles and behaviors, we identified
							ideal regions for growth. Additionally, their platform enabled us
							to develop highly targeted marketing campaigns. Their data-driven
							approach has been instrumental in our growth and success
						</p>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	)
}

export default ClientsSay
