import React from 'react'
import '../styles/Footer.css'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import twiiterIcon from '../images/twitter.png'
import linkedinIcon from '../images/linkedin.png'

function Footer() {
	function twitterClick() {
		window.open('https://twitter.com/sociometrik_org')
	}

	function linkedinClick() {
		window.open('https://www.linkedin.com/company/sociometrik/')
	}

	return (
		<footer>
			<div class='footer-container'>
				<div class='footer-links'>
					<div class='footer-logo'>
						<img
							src={require('../images/footerlogo.png')}
							alt='logo'
							width={250}
						/>
					</div>
					<div className='footer-features'>
						<div class='footer-column'>
							<h3 className='footer-feat-title'>Features</h3>
							<ul>
								<li>Site Selection</li>
								<li>Site Recommendation</li>
								<li>Market Expansion</li>
								<li>Site Monitoring</li>
							</ul>
						</div>
						<div class='footer-column'>
							<h3 className='footer-feat-title'>Industries</h3>
							<ul>
								<li>
									<NavLink to={'/malls'}>Malls </NavLink>
								</li>
								<li>
									<Link to={'/fashion-apparel'}>Fashion Apparel</Link>
								</li>
								<li>
									<Link to={'/beauty-and-skincare'}>Beauty & skincare </Link>
								</li>
								<li>
									<Link to={'/qsr'}>Restaurant</Link>
								</li>
								<div className='hidden-on-desktop'>
									<li>
										<Link to={'/jewellery-and-wedding-wear'}>
											Jewellery & Weddingwear
										</Link>
									</li>
									<li>
										<Link to={'/sports-and-athleisure'}>
											Sports & Athleisure{' '}
										</Link>
									</li>
									<li>
										<Link to={'/luxury-goods'}>Bridge to Luxury Brands </Link>
									</li>
								</div>
							</ul>
						</div>
						<div class='footer-column hidden-mobile'>
							<h3 className='footer-feat-title-hidden'>Industries</h3>
							<ul>
								<li>
									<Link to={'/jewellery-and-wedding-wear'}>
										Jewellery & Weddingwear
									</Link>
								</li>
								<li>
									<Link to={'/sports-and-athleisure'}>
										Sports & Athleisure{' '}
									</Link>
								</li>
								<li>
									<Link to={'/luxury-goods'}>Bridge to Luxury Brands </Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class='footer-bottom'>
					<p className='rights'>© All rights reserved 2024</p>

					<div class='contact-info'>
						<a href='mailto:contact@sociometrik.com'>contact@sociometrik.com</a>
						<a href='tel:+919821590270'>+91-9821590270</a>
					</div>
					<div class='social-icons'>
						<a href='https://x.com/sociometrik_org' target='__blank'>
							<img src={twiiterIcon} alt='Twitter' style={{ width: '40px' }} />
						</a>
						<a
							href='https://www.linkedin.com/company/sociometrik/'
							target='__blank'>
							<img src={linkedinIcon} alt='LinkedIn' />
						</a>
					</div>
					<div class='social-icons-hidden'>
						<a href='https://x.com/sociometrik_org' target='__blank'>
							<img src={twiiterIcon} alt='Twitter' style={{ width: '40px' }} />
						</a>
						<a
							href='https://www.linkedin.com/company/sociometrik/'
							target='__blank'>
							<img src={linkedinIcon} alt='LinkedIn' />
						</a>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
