import React from 'react'
import '../styles/ClientList.css'

function ClientList() {
	return (
		<div className='client-list-container'>
			<div className='client-list'>
				<img src={require('../images/Client List/03_logo.webp')} alt='undp' />
				<img
					src={require('../images/Client List/01_Logo.webp')}
					alt='accenture'
				/>
				{/* <img src={require('../images/Client List/02_Logo.webp')} alt='mpbirla' /> */}
				<img
					src={require('../images/Client List/05_logo.webp')}
					alt='br'
					style={{ height: '50px' }}
				/>
				<img
					src={require('../images/Client List/04_logo.webp')}
					alt='tata steel'
				/>
				<img
					src={require('../images/Client List/11_logo.webp')}
					alt='DLF'
					style={{ height: '45px' }}
				/>
				<img
					src={require('../images/Client List/09_logo.webp')}
					alt='pacific malls'
				/>
				{/* <img
					src={require('../images/Client List/logo_13.webp')}
					alt='Tasva'
					style={{ height: '50px' }}
				/> */}
				{/* <img
					src={require('../images/Client List/logo_15.webp')}
					alt='Jaypore'
					style={{ height: '60px' }}
				/> */}
				<img
					src={require('../images/Client List/logo_14.webp')}
					alt='Vegas'
					style={{ height: '50px' }}
				/>
				<img
					src={require('../images/Client List/logo_17.webp')}
					alt='CCD'
					style={{ height: '60px' }}
				/>
				<img
					src={require('../images/Client List/06_Logo.webp')}
					alt='medulance'
				/>
				{/* <img src={require('../images/Client List/07_Logo.webp')} alt='hdfc' /> */}
				{/* <img src={require("../images/Client List/10_logo.webp")} alt="bptp" /> */}

				<img
					src={require('../images/Client List/logo_12.webp')}
					alt='Eternia'
				/>

				<img
					src={require('../images/Client List/logo_16.webp')}
					alt='Zenma'
					// style={{ height: '50px' }}
				/>
				{/* repeated */}
				<img src={require('../images/Client List/03_logo.webp')} alt='undp' />
				<img
					src={require('../images/Client List/01_Logo.webp')}
					alt='accenture'
				/>
				{/* <img src={require('../images/Client List/02_Logo.webp')} alt='mpbirla' /> */}

				<img
					src={require('../images/Client List/05_logo.webp')}
					alt='br'
					style={{ height: '50px' }}
				/>
				<img
					src={require('../images/Client List/04_logo.webp')}
					alt='tata steel'
				/>
				<img
					src={require('../images/Client List/11_logo.webp')}
					alt='DLF'
					style={{ height: '45px' }}
				/>
				<img
					src={require('../images/Client List/09_logo.webp')}
					alt='pacific malls'
				/>
				<img
					src={require('../images/Client List/logo_13.webp')}
					alt='Tasva'
					style={{ height: '50px' }}
				/>
				<img
					src={require('../images/Client List/logo_15.webp')}
					alt='Jaypore'
					style={{ height: '60px' }}
				/>
				<img
					src={require('../images/Client List/logo_14.webp')}
					alt='Vegas'
					style={{ height: '50px' }}
				/>
				<img
					src={require('../images/Client List/logo_17.webp')}
					alt='CCD'
					style={{ height: '60px' }}
				/>
				<img
					src={require('../images/Client List/06_Logo.webp')}
					alt='medulance'
				/>
				{/* <img src={require('../images/Client List/07_Logo.webp')} alt='hdfc' /> */}
				{/* <img src={require("../images/Client List/10_logo.webp")} alt="bptp" /> */}

				<img
					src={require('../images/Client List/logo_12.webp')}
					alt='Eternia'
				/>

				<img
					src={require('../images/Client List/logo_16.webp')}
					alt='Zenma'
					// style={{ height: '50px' }}
				/>
			</div>
		</div>
	)
}

export default ClientList
