import React from 'react';
import '../styles/UseCases.css';
import { NavLink } from 'react-router-dom';
import malls from '../images/industry/bi_buildings.png';
import qsr from '../images/industry/teenyicons_shop-outline.png';
import bs from '../images/industry/icon-park-outline_beauty.png';
import fashion from '../images/industry/icon-park_clothes-suit.png';
import jewellery from '../images/industry/file-icons_ring.png';
import sports from '../images/industry/fluent-mdl2_more-sports.png';
import brands from '../images/industry/Vector.png';

function UseCases() {
  return (
    <div className="useCaseSection">
      <h1 className="headingUseCase">Industry specific use cases</h1>
      <div className="industries">
        <NavLink to={'/malls'} className="industryCard">
          <div className="indIcon">
            <img src={malls} alt="" />
          </div>
          <div>
            <h3>Malls</h3>

            <span>See More</span>
          </div>
        </NavLink>
        <NavLink to={'/qsr'} className="industryCard">
          <div className="indIcon">
            <img src={qsr} alt="" />
          </div>
          <div>
            <h3>QSR</h3>

            <span>See More</span>
          </div>
        </NavLink>
        <NavLink to={'/beauty-and-skincare'} className="industryCard">
          <div className="indIcon">
            <img src={bs} alt="" />
          </div>
          <div>
            <h3>Beauty & Skincare</h3>

            <span>See More</span>
          </div>
        </NavLink>
        <NavLink to={'/fashion-apparel'} className="industryCard">
          <div className="indIcon">
            <img src={fashion} alt="" />
          </div>
          <div>
            <h3>Fashion Apparel</h3>

            <span>See More</span>
          </div>
        </NavLink>
        <NavLink to={'/jewellery-and-wedding-wear'} className="industryCard">
          <div className="indIcon">
            <img src={jewellery} alt="" />
          </div>
          <div>
            <h3>Jewellery & Wedding wear</h3>

            <span>See More</span>
          </div>
        </NavLink>
        <NavLink to={'/sports-and-athleisure'} className="industryCard">
          <div className="indIcon">
            <img src={sports} alt="" />
          </div>
          <div>
            <h3>Sports & Athleisure</h3>

            <span>See More</span>
          </div>
        </NavLink>
        <NavLink to={'/luxury-goods'} className="industryCard">
          <div className="indIcon">
            <img src={brands} alt="" />
          </div>
          <div>
            <h3>Bridge to Luxury Brands</h3>

            <span>See More</span>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default UseCases;
