import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/innerPage.css';
import audience from '../images/fashion/1.png';
import store from '../images/fashion/2.png';
import benchmarking from '../images/fashion/3.png';
import promotion from '../images/fashion/4.png';

function FashionApparel() {
  return (
    <>
      <Header />
      <div className="header">
        <h1>
          <span className="higlightText">Fashion Apparel </span> use case
        </h1>
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p> */}
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            <span className="higlightText">Targeted Store </span> Placement
          </h2>
          <p className="content">
            Utilize location and consumer data to identify prime locations for
            new fashion stores. Ensure maximum foot traffic and visibility by
            targeting areas with high potential customer density.
          </p>
        </div>
        <div>
          <img src={audience} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            Customer <span className="higlightText"> Insights and Trends</span>
          </h2>
          <p className="content">
            Gain deep insights into customer preferences, purchasing behaviors,
            and fashion trends. Tailor your product offerings to meet evolving
            consumer demands and stay ahead of the competition.
          </p>
        </div>
        <div>
          <img src={store} alt="" />
        </div>
      </div>

      <div className="imgLeftContainer">
        <div>
          <h2>
            Optimized{' '}
            <span className="higlightText"> Inventory Management</span>
          </h2>
          <p className="content">
            Use real-time data to manage your inventory effectively. Ensure the
            right products are available at the right time, reducing overstock
            and stockouts, and improving customer satisfaction.
          </p>
        </div>
        <div>
          <img src={benchmarking} alt="" />
        </div>
      </div>

      <div className="imgRightContainer">
        <div>
          <h2>
            <span className="higlightText">Enhanced Marketing </span> Campaigns
          </h2>
          <p className="content">
            Leverage data intelligence to create personalized marketing
            campaigns. Target specific consumer segments with tailored
            promotions, driving engagement, brand loyalty, and sales growth..
          </p>
        </div>
        <div>
          <img src={promotion} alt="" />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default FashionApparel;
